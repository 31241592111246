<template>
  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <svg width="40" height="40"><use xlink:href="#kennel"></use></svg>
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">譲渡対象リスト</h1>
      </div>
      <div class="control-group">
        <button
          class="btn btn-warning btn-lg"
          @click.prevent="addAnimal"
        >＋追加</button>
      </div>
    </div>
  </header>

  <main class="p-3">

    <table class="table table-striped">
      <thead>
        <tr>
          <th class="pic">写真</th>
          <th class="status">状態</th>
          <th class="belong">所属</th>
          <th class="species">犬種</th>
          <th class="age">年齢</th>
          <th class="mcnum">マイクロチップNo.</th>
          <th class="action">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="animal in animalList"
          :key="animal.ID"
        >
          <td>
            <picture>
              <img :src="animal.thumb_url" :alt="animal.name">
            </picture>
          </td>
          <td
            class="status"
            :class="{onsale: animal.onsale}"
          >
            <span v-if="animal.onsale">公開中</span>
            <span v-else>未公開</span>
          </td>
          <td>{{animal.belongs}}</td>
          <td>{{animal.species}}</td>
          <td>{{animal.age}}才</td>
          <td>{{animal.microchip_no}}</td>
          <td class="action">
            <button
              v-if="animal.onsale"
              class="btn btn-secondary me-2"
              @click="suspend(animal.ID)"
            >公開停止</button>
            <button
              v-else
              class="btn btn-primary me-2"
              @click="release(animal.ID)"
            >公開中</button>
            <button
              class="btn btn-dark"
              @click="remove(animal.ID)"
            >削除</button>
          </td>
        </tr>
      </tbody>
    </table>

  </main>

</template>

<script>
import axios from "axios"
export default {
  data() {
    return {
      animalList: []
    }
  },
  mounted() {
    this.loadList()
    this.$store.watch(
      (state, getters) => getters['Mediate/reload'],
      (newValue) => {
        if(newValue){
          this.loadList()
          this.$store.commit('Mediate/reload', false)
        }
      }
    )
  },
  methods: {
    loadList() {
      axios
        .get("/wp-json/pasti/v1/mediate-animal")
        .then((response) => {
          console.log(response.data)
          this.animalList = response.data
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addAnimal() {
      this.$router.push('/mediate-animal')
    },
    suspend(id) {
      axios
        .post("/wp-json/pasti/v1/mediate-animal/changestatus", {
          id: id,
          onsale: false
        })
        .then((response) => {
          console.log(response.data)
          this.loadList()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    release(id) {
      axios
        .post("/wp-json/pasti/v1/mediate-animal/changestatus", {
          id: id,
          onsale: true
        })
        .then((response) => {
          console.log(response.data)
          this.loadList()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    remove(id) {
      if( confirm("削除しますか？") ){
        axios
          .post("/wp-json/pasti/v1/mediate-animal/delete", {
            id: id
          })
          .then((response) => {
            console.log(response.data)
            this.loadList()
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  }
}
</script>

<style scoped>
th,td { width: 10%; }
th.species,
td.species { width: 30%; }
th.mcnum,
td.mcnum { width: 15%; }
th.action,
td.action { width: 15%; }
td picture {
  display: block;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}
td picture img {
  width: 100%;
  height: auto;
}
</style>